.action {
  background: $white;
  width: 50px;
  height: 50px;
  line-height: 54px;
  color: #9a9a9a;
  font-size: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50%;
  box-shadow: 0px 0px 9.3px 0.7px rgba(0, 0, 0, 0.06);
  transition: 0.3s ease-out;
  &:hover {
    background: $danger;

    button {
      color: $white;
    }
  }
  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    line-height: 1;
    background: transparent;
    transition: 0.3s ease-out;
  }
}

.actions-verticale {
  .action {
    position: absolute;
    top: 20px;
    right: -100%;
    z-index: 6;
    opacity: 0;
    visibility: hidden;

    &:nth-child(1) {
      transition: 0.5s ease-out 0s;
    }

    &:nth-child(2) {
      top: 80px;
      transition: 0.7s ease-out 0s;
    }

    &:nth-child(3) {
      top: 140px;
      transition: 0.9s ease-out 0s;
    }
  }
}

// actions horizontal
.actions-horizontal {
  display: flex;
  flex-wrap: wrap;
  .action {
    & + .action {
      margin-left: 10px;
    }
  }
}
