/*--------------------------------------
/*  [ 4.1 actions CSS ]
----------------------------------------*/
@import "components/actions";
/*--------------------------------------
/*  [ 4.2 button CSS ]
----------------------------------------*/
@import "components/button";
/*--------------------------------------
/*  [ 4.3 count CSS ]
----------------------------------------*/
@import "components/count";
/*--------------------------------------
/*  [ 4.4 hero CSS ]
----------------------------------------*/
@import "hero";
/*--------------------------------------
/*  [ 4.5 banner CSS ]
----------------------------------------*/
@import "components/banner";
/*--------------------------------------
/*  [ 4.6 bread crumb CSS ]
----------------------------------------*/
@import "components/bread-crumb";
/*--------------------------------------
/*  [ 4.7 brand carousel CSS ]
----------------------------------------*/
@import "components/brand-carousel";
/*--------------------------------------
/*  [ 4.8 products CSS ]
----------------------------------------*/
@import "components/products";
/*--------------------------------------
/*  [ 4.9 decoration CSS ]
----------------------------------------*/
@import "components/decoration";
/*--------------------------------------
/*  [ 4.10 categoris CSS ]
----------------------------------------*/
@import "components/categoris";
/*--------------------------------------
/*  [ 4.11 news letter CSS ]
----------------------------------------*/
@import "components/news-letter";
/*--------------------------------------
/*  [ 4.12 blogs CSS ]
----------------------------------------*/
@import "components/blogs";
/*--------------------------------------
/*  [ 4.13 blog details CSS ]
----------------------------------------*/
@import "components/blog-details";
/*--------------------------------------
/*  [ 4.14 footer CSS ]
----------------------------------------*/
@import "components/footer";
/*--------------------------------------
/*  [ 4.15 product modal CSS ]
----------------------------------------*/
@import "components/product-modal";
/*--------------------------------------
/*  [ 4.16 pagination CSS ]
----------------------------------------*/
@import "components/pagination";
/*--------------------------------------
/*  [ 4.17 shop CSS ]
----------------------------------------*/
@import "components/shop";
/*--------------------------------------
/*  [ 4.18 single produt tab CSS ]
----------------------------------------*/
@import "components/single-produt-tab";
/*--------------------------------------
/*  [ 4.19 checkout CSS ]
----------------------------------------*/
@import "components/checkout";
/*--------------------------------------
/*  [ 4.20 my account CSS ]
----------------------------------------*/
@import "components/my-account";
/*--------------------------------------
/*  [ 4.21 compare CSS ]
----------------------------------------*/
@import "components/compare";
/*--------------------------------------
/*  [ 4.22 wishlist CSS ]
----------------------------------------*/
@import "components/wishlist";
/*--------------------------------------
/*  [ 4.23 contact CSS ]
----------------------------------------*/
@import "components/contact";
/*--------------------------------------
/*  [ 4.24 about us CSS ]
----------------------------------------*/
@import "components/about-us";
/*--------------------------------------
/*  [ 4.25 static info CSS ]
----------------------------------------*/
@import "components/static-info";
/*--------------------------------------
/*  [ 4.26 service CSS ]
----------------------------------------*/
@import "components/service";
/*--------------------------------------
/*  [ 4.27 404 CSS ]
----------------------------------------*/
@import "components/404";
/*--------------------------------------
/*  [ 4.28 policy CSS ]
----------------------------------------*/
@import "components/policy";
/*--------------------------------------
/*  [ 4.29 accordion CSS ]
----------------------------------------*/
@import "components/accordion";
