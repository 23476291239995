.hero-slider {
  position: relative;
  .hero-slide-item {
    display: flex;
    align-items: center;
    &.swiper-slide-active {
      .title {
        &.delay1 {
          animation: zoomInLeft 1s linear;
        }
      }
      .title {
        &.delay2 {
          animation: zoomInLeft 1.3s linear;
        }
      }
      .text {
        animation: zoomInLeft 1.6s linear;
      }

      .btn {
        animation: zoomInLeft 1.9s linear;
      }
    }
  }
}

.slider-height1 {
  @include min-screen(1200) {
    height: 573px;
  }
  @include min-screen(1920) {
    height: 852px;
  }
  @include max-screen(992, 1199) {
    height: 525px;
  }
  @include max-screen(768, 991) {
    height: 450px;
  }

  @include max-screen(767) {
    height: 400px;
  }
}

.slider-height2 {
  @include min-screen(1200) {
    height: 573px;
  }
  @include min-screen(1920) {
    height: 905px;
  }
  @include max-screen(992, 1199) {
    height: 525px;
  }
  @include max-screen(768, 991) {
    height: 450px;
  }

  @include max-screen(767) {
    height: 400px;
  }
}

.hero-slide-content {
  padding-left: 15px;
  @include min-screen(992) {
    padding-left: 50px;
  }

  .title {
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    font-size: 60px;
    margin-bottom: 0;
    @include max-screen(991) {
      font-size: 45px;
      line-height: 1.3;
    }
    @include max-screen(767) {
      font-size: 35px;
      line-height: 1.3;
    }
    @include max-screen(480) {
      font-size: 35px;
    }
  }

  .text {
    background: $dark;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 25px;
    padding: 5px 15px;
    font-weight: 400;
    line-height: 1.3;
    font-size: 16px;
    border-radius: 5px;
    @include max-screen(575) {
      font-size: 14px;
    }
  }

  .btn {
    margin-top: 40px;
  }
}

.slide-bg1 {
  background-image: url("../images/slider/slide1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg2 {
  background-image: url("../images/slider/slide2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg3 {
  background-image: url("../images/slider/slide3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg4 {
  background-image: url("../images/slider/slide4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg5 {
  background-image: url("../images/slider/slide5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg6 {
  background-image: url("../images/slider/slide6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg7 {
  background-image: url("../images/slider/slide7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}
.slide-bg8 {
  background-image: url("../images/slider/slide8.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% center;
}


// swiper navigation
.hero-slider {
  .swiper-button-next,
  .swiper-button-prev {
    background: $white;
    width: 50px;
    height: 50px;
    line-height: 54px;
    color: #9a9a9a;
    font-size: 30px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: ease-in-out 0.3s;
    border-radius: 50%;
    box-shadow: 0px 0px 9.3px 0.7px rgba(0, 0, 0, 0.06);

    &:hover {
      background: $danger;
      color: $white;
    }
  }

  .swiper-button-next {
    right: 20px;
  }
  .swiper-button-prev {
    left: 20px;
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
      visibility: visible;
    }

    .swiper-button-next {
      right: 30px;
    }
    .swiper-button-prev {
      left: 30px;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}

// swiper-pagination
.hero-slider {
  .swiper-pagination-bullet {
    margin: 0 5px;
    width: 14px;
    height: 14px;
    border: 2px solid #dedede;
    background: #dedede;
    opacity: 1;
    border-radius: 100%;
    transition: all 0.3s ease-in;
    &.swiper-pagination-bullet-active,
    &.swiper-pagination-bullet:hover {
      background: $dark;
      border-color: $dark;
    }
  }
}

.hero-slider {
  .swiper-pagination {
    bottom: 30px;
    left: 0;
    width: 100%;
  }
}
