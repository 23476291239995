.banner {
  position: relative;
  .label {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: $white;
    font-size: 1.2857em;
    padding: 10px 30px;
    font-weight: 500;
    z-index: 1;
    text-align: center;
    color: $dark;
  }
}

.thumb-nail {
  display: block;
  overflow: hidden;
  img {
    width: 100%;
    transition: 0.3s ease-out 0s;
  }
  &:hover {
    img {
      transform: scale(1.02);
    }
  }
}

.large-thumb-nail {
  overflow: hidden;
  @include min-screen(992) {
    display: inline-block;
  }

  @include min-screen(1200) {
    padding-left: 50px;
  }
  img {
    transition: 0.3s ease-out 0s;
    @include max-screen(991) {
      width: 100%;
    }
  }
  &:hover {
    img {
      transform: scale(1.02);
    }
  }
}
.small-thumb-nail {
  display: inline-block;
  overflow: hidden;
  img {
    transition: 0.3s ease-out 0s;
  }
  &:hover {
    img {
      transform: scale(1.02);
    }
  }
}

.banner-content {
  position: absolute;
}
.banner-position-top-center {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
}

.banner-position-top-left {
  top: 80px;
  left: 75px;
  @include max-screen(575) {
    top: 20px;
  }
}
.banner-position-bottom-left {
  bottom: 80px;
  left: 55px;
}
.banner-sub-title {
  font-size: 55px;
  font-weight: 300;
  line-height: 1;
  text-transform: capitalize;
  color: #fff;
  @include max-screen(1499) {
    font-size: 35px;
  }
}
.banner-title {
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #fff;

  @include max-screen(1200, 1499) {
    font-size: 55px;
  }
  @include max-screen(992, 1199) {
    font-size: 45px;
  }

  @include max-screen(767) {
    font-size: 45px;
  }
  @include max-screen(575) {
    font-size: 35px;
  }
}

.home-style2 {
  .banner-sub-title {
    font-size: 35px;
  }
}

// large banner content style

.large-banner-content {
  padding-top: 60px;
  margin-bottom: 60px;

  .btn {
    border-width: 2px;
    & + .btn {
      @include min-screen(576) {
        margin-left: 10px;
      }
    }
  }
}
.large-banner-sub-title {
  font-size: 34px;
  font-weight: 300;
  line-height: 1;
  color: $dark;
}
.large-banner-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: $dark;
}

.small-thumb-nail {
  position: absolute;
  top: 50%;
  right: 0;
  margin-bottom: 35px;
  transform: translateY(-50%);
  border: 17px solid #fff;
  box-shadow: 0 0 12.75px 2.25px rgba(0, 0, 0, 0.06);
  @include max-screen(1199) {
    max-width: 250px;
  }
}

.large-banner-content-wrap {
  @include min-screen(1200) {
    padding-left: 11%;
    padding-top: 150px;
  }
  p {
    font-size: 1.1429em;
    font-weight: 600;
    margin: 15px 0 35px;
    color: #9a9a9a;
    text-transform: uppercase;
    letter-spacing: 0.12em;
  }

  .btn {
    margin: 30px 0px;
    @include min-screen(1200) {
      margin-top: 70px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      span {
        display: block;
        text-transform: uppercase;
        color: $dark;
      }
    }
  }
}

.banner-v3 {
  top: 50px;
  left: 65px;
  right: auto;
  text-align: left;
  .banner-sub-title {
    color: $dark;
    font-size: 35px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0px;
    display: block;
  }
  .btn {
    padding: 0px;
    &.btn-light {
      color: #222;
      font-weight: 600;
      margin-top: 20px;
      background: transparent;
      border-color: transparent;
      text-decoration: underline;
      &:hover {
        color: $danger;
      }
    }
  }
}
