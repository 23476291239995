/*-------------------------------------------------------------

  #  Template Name: Moller - Furniture & Decor eCommerce Responsive Bootstrap5 Template
  #  Author   Name: Hastech
  #  Version: 1.0

-------------------------------------------------------------
    
  #   CSS INDEX
========================================================
	
  [ 01 Template default CSS ]

  [ 02 Header CSS ]

  [ 03 Widgets CSS ]

  [ 04 Component CSS ]
    
  [ 4.1 actions CSS ]

  [ 4.2 button CSS ]

  [ 4.3 count CSS ]

  [ 4.4 hero CSS ]

  [ 4.5 banner CSS ]

  [ 4.6 bread crumb CSS ]

  [ 4.7 brand carousel CSS ]

  [ 4.8 products CSS ]

  [ 4.9 decoration CSS ]

  [ 4.10 categoris CSS ]

  [ 4.11 news letter CSS ]

  [ 4.12 blogs CSS ]

  [ 4.13 blog details CSS ]

  [ 4.14 footer CSS ]

  [ 4.15 product modal CSS ]

  [ 4.16 pagination CSS ]

  [ 4.17 shop CSS ]

  [ 4.18 single produt tab CSS ]

  [ 4.19 checkout CSS ]

  [ 4.20 my account CSS ]

  [ 4.21 compare CSS ]

  [ 4.22 wishlist CSS ]

  [ 4.23 contact CSS ]

  [ 4.24 about us CSS ]

  [ 4.25 static info CSS ]

  [ 4.26 service CSS ]

  [ 4.27 404 CSS ]

  [ 4.28 policy CSS ]

  [ 4.29 accordion CSS ]

---------------------------------------------------*/

/*--------------------------------------
/*  [ 01 Template default CSS ]
----------------------------------------*/

@import "variables";
@import "mixin";
@import "common";
/*--------------------------------------
/*  [ 02 Header CSS ]
----------------------------------------*/
@import "header";
/*--------------------------------------
/*  [ 03 Widgets CSS ]
----------------------------------------*/
@import "widgets";
/*--------------------------------------
/*  [ 04 Component CSS ]
----------------------------------------*/
@import "components";
