.product-count.style input {
  color: #1d1d1d;
  background-color: #fff;
  height: 48px;
  padding: 10px 30px 10px 10px;
  width: 70px;
  border: 1px solid #ebebeb;
  display: block;
  text-align: center;
  -moz-appearance: textfield;
  appearance: textfield;
  &:focus {
    outline: none;
  }
}

.product-count.style .count-btn {
  font-weight: 700;
  background-color: transparent;
  border: 0;
  border-left: 1px solid #ebebeb;
  display: block;
  width: 20px;
  height: 24px;
  padding: 0;
  text-align: center;
  color: #1d1d1d;
  font-size: 10px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-count.style .increment {
  border-bottom: 1px solid #ebebeb;
}
