// media

.media {
  position: relative;
  display: flex;

  & .thumb {
    margin-right: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    right: -5px;
    height: 85%;
    width: 1px;
    background: #f1f1f1;
    top: 15px;
  }
}
.media {
  &.media-2 {
    display: flex;

    span {
      align-self: center;
      font-size: 32px;
      line-height: 32px;
      color: $white;
      display: inline-block;
      margin-right: 20px;
      @include max-screen(992, 1199) {
        font-size: 24px;
      }
    }
    &::after {
      display: none;
    }

    .title {
      color: $white;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;

      @include max-screen(992, 1199) {
        font-size: 13px;
      }
    }
    p {
      font-size: 14px;
      color: $white;
      font-weight: normal;
    }
  }
}

.media-body {
  flex: 1;
}

.single-product-tab {
  .nav-item {
    margin: 0 30px;
    @include max-screen(767) {
      margin: 0 20px;
    }
    @include max-screen(575) {
      margin: 0 10px;
    }
    .nav-link {
      line-height: 1.388;
      font-weight: 600;
      color: $dark;
      padding: 0px 15px 30px;
      @include max-screen(767) {
        padding: 15px 10px;
        font-size: 15px;
      }
      @include max-screen(575) {
        padding: 15px 5px;
        font-size: 14px;
      }
      font-size: 18px;
      border: 0;
      border-bottom: 2px solid transparent;
      &.active {
        background-color: transparent;
        border-color: $danger;
        color: #ababab;
      }
      &:hover {
        background-color: transparent;
        border-color: $danger;
        color: #ababab;
      }
    }
  }
}

/************************
    single-product start
  ************************/

.single-product-head {
  .title {
    font-size: 30px;
  }

  .star-content {
    .star-on {
      margin: 0 1px 0 0;
      color: $warning;

      font-size: 18px;
    }
    a {
      font-size: 15px;

      span {
        margin-right: 6px;
      }
    }
  }
}

// product-tab-menu single-product

.single-product-desc {
  background: $white;
  padding: 30px;
  border: 1px solid $borderColor;
  ul {
    font-size: 15px;
    line-height: 24px;
    text-align: left;
  }
}

.studio-thumb {
  img {
    border: 1px solid $borderColor;
  }
  h3 {
    text-transform: capitalize;

    color: $dark;
    line-height: 24px;
    font-size: 0.9375rem;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem;
    color: $dark;

    small {
      font-size: 14px;
      color: $body-color;
    }
  }
}

.product-features {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    li {
      flex: 1 0 40%;
      padding: 0.625rem;
      margin-right: 0.625rem;
      min-height: 2.5rem;
      word-break: normal;
      text-transform: capitalize;
      margin-bottom: 0.5rem;
    }
  }
}

.grade-content {
  .grade {
    text-transform: capitalize;
    font-size: 14px;
    margin-right: 10px;
  }
  .star-on {
    color: $warning;

    font-size: 18px;
  }

  .title {
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .sub-title {
    color: $dark;
    margin-bottom: 10px;

    line-height: 18px;
    font-size: 14px;
    padding-top: 9px;
    margin-bottom: 10px;
  }
  p {
    line-height: 18px;
    font-size: 14px;
  }
}

.group-img {
  img {
    max-width: 80px;
    margin-right: 15px;
  }
}

.product-grouped {
  .title {
    margin-bottom: 0.8rem;
    color: $dark;
    font-size: 15px;
    text-transform: capitalize;
  }
  span {
    font-size: 14px;
    color: $body-color;
    line-height: 1;
  }
}
.product-anotherinfo-wrapper ul li {
  color: #888;
  font-size: 14px;
  list-style: outside none none;
  margin: 0 0 13px;
}
.product-anotherinfo-wrapper ul li span {
  color: $dark;
  display: inline-block;
  font-weight: 500;
  margin: 0 26px 0 0;
  min-width: 85px;
}
.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-review .review-top-wrap .review-left .review-name {
  margin-right: 25px;
}

.single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 16px;
  font-weight: 600;
}

.single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.rating-product {
  margin-bottom: 6px;
}

.rating-product i {
  overflow: hidden;
  font-size: 16px;
  color: #fdd835;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.review-left a {
  color: #565656;
  margin-left: auto;
}

.single-review .review-bottom p {
  margin: 0;
  max-width: 93%;
}

.ratting-form-wrapper .ratting-form form .star-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0 20px;
}
.ratting-form-wrapper h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.ratting-form-wrapper .ratting-form form .star-box span {
  margin: 0 15px 0 0;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}

.rating-form-style input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #253237;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.rating-form-style input[type="submit"] {
  padding: 0 55px !important;
  line-height: 48px;
  height: 48px;
  width: auto;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  text-transform: uppercase;
  background: $dark !important;
  color: #fff !important;
  transition: all 300ms linear;
  &:hover {
    background: $warning !important;
  }
}

/************************
    single-product End
  ************************/

.review-name {
  h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.single-form input,
.single-form textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}
.single-form input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: $dark;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}
.single-form textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.single-form input[type="submit"]:hover {
  background: $dark !important;
  color: #fff !important;
}
.single-blog .blog-post-content-inner {
  padding: 30px 0 0;
}

.comment-area .single-review.child-review {
  margin-bottom: 70px;
}
.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 50px;
  @include max-screen(767) {
    flex-direction: column;
  }
}

.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
  @include max-screen(767) {
    margin-bottom: 30px;
  }
}

.single-review {
  img {
    max-width: 120px;
  }
}

.single-form {
  label {
    margin-bottom: 15px;
  }
}
