.product-modal-gallery {
  margin-bottom: 30px;
}
.product-modal-gallery-item {
  text-align: center;
}

.product-modal-gallery-thumbs-item {
  text-align: center;
  padding: 5px;
  &.swiper-slide-thumb-active {
    a {
      border-color: $danger;
    }
  }
  a {
    display: block;
    border: 1px solid transparent;
    &:hover {
      border-color: $danger;
    }
  }
}

.gallery {
  margin-bottom: 30px;
}
.gallery-item {
  text-align: center;
}

.gallery-thumbs-item {
  text-align: center;
  padding: 5px;
  &.swiper-slide-thumb-active {
    a {
      border-color: $danger;
    }
  }
  a {
    display: block;
    border: 1px solid transparent;
    &:hover {
      border-color: $danger;
    }
  }
}

.modal-product-title {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 20px;
  color: $dark;
  font-size: 22px;
  text-transform: capitalize;
  &:hover {
    color: $danger;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

.modal-product-sub-title {
  font-weight: 700;
  font-size: 14px;
  color: $dark;
}

.product-description-short {
  padding: 0 0 30px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.product-variants {
  display: flex;
  margin-bottom: 25px;
}

.product-variants-item {
  & + .product-variants-item{
    margin-left: 30px;
  }
  ul {
    display: flex;
  }
  .form-control {
    background-color: $white;
    color: $dark;
    border: 1px solid #ebebeb;
    font-size: 14px;
  }
}

.product-variants > .product-variants-item ul li {
  margin-right: 0.625rem;
}

.input-container {
  position: relative;
}

.product-variants > .product-variants-item label {
  margin: 0;
}
.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}
.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin-top: 10px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
  border: 2px solid #232323;
}
.input-color:checked + span,
.input-radio:checked + span {
  background-color: #434a54;
}

.control-label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: $dark;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.form-control-select {
  width: auto;
  padding-right: 1.875rem;
  background-color: $white;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0px;
  background: #f1f1f1
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=)
    no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
  background-color: rgb(241, 241, 241);
}

.product-modal-dialog {
  max-width: 64rem;
  .modal-header {
    border-bottom: 0px;
  }
}

.product-price-wrapp-lg {
  margin-bottom: 30px;
}
.product-regular-price-lg {
  font-weight: 600;
  line-height: 20px;
  color: $dark;
  font-size: 22px;
  text-decoration: line-through;
  margin-right: 0.625rem;
}

.product-price-on-sale-lg {
  color: $danger;
  font-size: 22px;
  line-height: 1.3;
}

.badge {
  &.badge-lg {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $white;
    margin-left: 0.625rem;
    padding: 0 10px 0 10px;
    background: $dark;
    vertical-align: 4px;
    border-radius: 0;
    text-transform: capitalize;
  }
}

.product-add-to-card-item {
  line-height: 30px;
  color: $dark;
  font-size: 15px;
  margin-right: 15px;
  &:hover {
    color: $danger;
    i {
      animation: rotate 2s infinite;
    }
  }

  i {
    display: inline-block;
    margin-right: 5px;
    font-size: 15px;
  }
}

.product-social-sharing {
  margin-top: 10px;
  span {
    line-height: 30px;
    font-weight: 600;
    color: $dark;
    font-size: 1.2em;
    margin: 0 0 15px;
    text-transform: uppercase;
    display: block;
  }
  ul {
    display: inline-block;

    li {
      margin-right: 20px;
      display: inline-block;
      a {
        background: #fff;
        border: 1px solid;
        color: #9a9a9a;
        display: inline-block;
        font-size: 18px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        width: 40px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        &:hover {
          color: $danger;
        }
      }
    }
  }
}

.modal {
  &.fade {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &.show {
      display: block !important;
      opacity: 1;
      visibility: visible;
    }
  }
}

// compare-modal-dialog
.compare-modal-dialog {
  .modal-header {
    border-bottom: 0px;
  }

  .modal-body {
    text-align: center;
    padding: 0 44px 44px 44px;
    p {
      i {
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }
}
// wishlist-modal-dialog
.wishlist-modal-dialog {
  .modal-header {
    border-bottom: 0px;
  }

  .modal-body {
    text-align: center;
    padding: 0 44px 44px 44px;
  }
}

// add to cart

.modal-quantity {
  color: $dark;
  font-size: 16px;
  margin-bottom: 0.375rem;
}

#addto-cart-modal {
  .modal-header {
    padding: 15px;
    font-weight: 600;
    line-height: 1;
  }
  .modal-dialog {
    max-width: 1140px;
  }
  & .modal-title {
    font-weight: 600;
    color: $white;
    font-size: 1.125rem;
  }
  & .ion-android-done {
    color: $white;
    font-size: 24px;
  }

  & .product-name {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    color: $dark;
  }

  & .btn-close {
    right: 20px;
    color: $white !important;
    background: none;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 $white;
    padding: 0px;
  }
}

.quntity-list {
  li {
    font-size: 14px;
    font-weight: 600;
    color: $dark;
    margin-bottom: 10px;
  }
}

.cart-content {
  & .title {
    font-weight: 400;
    color: $dark;
  }
  & p {
    color: $dark;
    & span {
      font-weight: 600;
      color: $dark;
    }
  }
}

.cart-content-btn {
  margin-top: 25px;
}

// swiper-pagination
.product-modal-gallery-thumbs {
  .swiper-pagination-bullet {
    margin: 0 5px;
    width: 14px;
    height: 14px;
    border: 2px solid $danger;
    background: transparent;
    opacity: 1;
    border-radius: 100%;
    transition: all 0.3s ease-in;
    &.swiper-pagination-bullet-active,
    &.swiper-pagination-bullet:hover {
      background: $danger;
      border-color: $danger;
    }
  }
}

.product-modal-gallery-thumbs {
  .swiper-pagination {
    margin-top: 30px;
    position: static;
  }
}
.modal-header .btn-close,
.btn-close {
  border: 1px solid #e7e7e7;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0px !important;
  margin: 0 !important;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  line-height: 28px;
  opacity: 1;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 3px;
  &:hover {
    color: $danger;
    border-color: $danger;
  }
}

// reviews
.reviews {
  margin-bottom: 15px;
  span {
    color: $warning;
  }
}
