.decoration-thumb {
  display: block;
}

.decoration-content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 1px;
  opacity: 0;
  visibility: hidden;

  .btn {
    @include min-screen(1200) {
      padding: 12px 35px;
    }
  }
}
.decoration-title {
  color: $dark;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 0 10px;
  text-transform: capitalize;
  font-size: 2em;
}

.swiper-slide-active {
  .decoration-content {
    opacity: 1;
    visibility: visible;
  }
}
