// contact-title-section

.contact-title-section {
  margin-bottom: 35px;
  .title {
    font-weight: 600;
    color: $dark;
    line-height: 1.4;
    font-size: 30px;
    margin-bottom: 0;

    @include min-screen(1500) {
      font-size: 35px;
    }
  }
}

// address

.address-list {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 30px;
  padding-bottom: 25px;
  color: #9a9a9a;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .title {
    font-weight: 600;
    color: $dark;
    line-height: 1.3;
    margin-bottom: 10px;
    font-size: 20px;
    span {
      color: #9a9a9a;
      font-size: 28px;
      margin-right: 10px;
    }
  }

  .phone-number,
  .mailto {
    color: $body-color;
    &:hover {
      color: $danger;
    }
  }
}

.contact-us-form {
  .form-control {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    border-color: #e7e7e7;
    margin-bottom: 20px;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $white;
    }
    &.massage-control {
      height: 150px;
      resize: none;
    }
  }
  .form-label {
    color: $body-color;
  }
}

.form-message.error {
  color: $danger;
}
.form-message.success {
  color: $success;
}
