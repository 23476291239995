.error-section {
  text-align: center;
  p {
   margin-left: auto;
   margin-right: auto;
    max-width: 600px;
    font-size: 1.1429em;
  }
}
.error-title {
  font-weight: 600;
  line-height: 1.5;
  color: $dark;
  font-size: 30px;
  text-transform: uppercase;
  @include min-screen(1500){
    font-size: 35px;
  }
}
.error-serch-form {
  max-width: 500px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  form {
    position: relative;
  }
  .form-control {
    padding: 0 55px 0 20px;
    color: #9a9a9a;
    font-size: 1em;
    height: 50px;
    line-height: 50px;
    border: 1px solid #e7e7e7;
    border-radius: 0;
    &:focus {
      box-shadow: none;
      &::placeholder {
        color: transparent;
      }
    }
  }
}
