// Developer Variable

// Color System

$white: #fff;
$gray-100: #f9f9f9;
$gray-600: #707070;
$gray-800: #222;

// Other Colors
$blue: #574186;
$red: #eb2d2d;
$yellow: #f9d738;
$green: #45ab49;
$cyan: #3d84c5;

// Theme Color
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);
// scss-docs-end theme-colors-map

// body color
$body-color: $gray-600;
$heading-color: $gray-800;
$borderColor:#ebebeb;
// Transition
$transition-base: all 0.3s ease 0s;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-enable value-keyword-case
$font-family-base: "Poppins", sans-serif;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-base: 14px;
//
//
$line-height-base: 1.814;
